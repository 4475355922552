<template>
<Loading :isLoading="isLoading"/>
    <div v-if="rulesLoaded">
        <div class="w-full">
            <p class="text-center font-bold text-3xl" style="color:#810042">
                Configuración de Registro de Pago de Comisión de Producto
            </p>
        </div>

        <div class="sm:flex my-10 w-full">
            <label class="w-full sm:w-4/5  xl:w-1/4  mt-2 " id="label_search_product_configure_commission">Buscar Producto:</label>
            <div class="w-full lg:w-3/5">
                <input type="text" class="shadow-md border-2 rounded-lg py-2 px-5 w-full outline-none focus:border-cherryColor" placeholder="Buscar..." 
                     id="input_search_product_configure_commission" v-model="v$.product_name.$model"
                    :class="{'invalid-form':v$.product_name.$error, 'valid-form':!v$.product_name.$invalid}"
                    :maxlength="(validationsStructure.validations.search.maxLength+1)"
                     v-on:keyup.delete="searchAfterDelete()"
                        v-on:keyup.enter="getSaleCommissionConfigHistory()">
                    <div v-for="(error,index) of v$.product_name.$errors" :key="index">
                        <div class="errors">{{ error.$message }}</div>
                    </div>
            </div>
            <div class="w-full" >
                <button class="w-full mt-5 p-2 sm:mt-0 sm:w-auto sm:ml-2 " style="background:#810042;border-radius:5px;" id="icon_search_product_configure_commission" @click="getSaleCommissionConfigHistory()">
                    <img src="@/assets/lupa.svg" height="10" width="25" style="margin:auto;" >
                </button>
            </div>
        </div>

        <div>
            <div v-if="responseProducts.length==0" class="text-center text-red-600 font-bold	">
                No se encontro información para mostrar
            </div>
            <table class="width200"  v-if="responseProducts.length>0" >
                <thead>
                    <tr>
                        <th>Producto</th>
                        <th>Comisiones en:</th>
                        <th>Última Actualización</th>
                        <th>Creado por</th>
                        <th>Fecha de registro</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(product, index) of responseProducts" :key="index">
                        <td><label :id="'label_product_configure_commission_'+index">{{product.product_name}}</label></td>
                        <td :id="'label_commission_in_configure_commission'+index">
                            <select class="shadow-md border-2 rounded-lg py-2 px-5 w-full outline-none focus:border-cherryColor" :id="'input_commission_in_configure_commission'+index" @change="updateSaleCommissionConfig($event.target.value,product)">
                                <option value="" disabled selected >Seleccione un producto</option>
                                <option v-for="(commission, index) in _commissionsResponse" :key="index" :value="commission.enrollment_step_name" v-bind:selected="product.enrollment_step_name==commission.enrollment_step_name" > {{commission.enrollment_step_name}}</option>
                                
                            </select>
                        </td>
                        <td><label :id="'label_last_update_configure_commission_'+index">{{ $filters.formatDate( product.updated_at)  }}</label></td>
                        <td><label :id="'label_created_by_configure_commission_'+index">
                            {{product.associate_info.name}} {{product.associate_info.paternal_name}}
                        </label></td>
                        <td><label :id="'label_registration_date_configure_commission_'+index">{{ $filters.formatDate( product.created_at) }}</label></td>
                        <td>
                            <button :id="'button_history_configure_commission'+index" class="shadow-md border-2 rounded-lg py-2  color-cherry-border w-full sm:w-auto button_history_configure_commission" 
                                style="border:2px solid #810042" @click="openHistoryModal(product)" >
                                <strong class="px-5">Historial</strong>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div>
            <div v-show="hidePagination">
                <Pagination ref="PaginationRef" :totalRowQuerry="totalRowQuerry" :maxQueryItems="10" v-on:change-pagination="getAssociateFiltred($event)" />
            </div>
        </div>

        <Modal v-if="showHistoryModal" :heightContainer="'auto'" :widthContainer="'30%'">
          <template v-slot:header>
            <h2 class="text-center text-xl" style="color:#810042" id="label_product _history_configure_commission" >Historial de {{selectProductNameModal}}</h2>
          </template>
          <template v-slot:body >
            <p class="description">Creado por: <strong id="label_created_by_configure_commission"> {{_nameUserCreateModal}} </strong></p>
            <p class="description" id="label_registration_date_configure_commission" >Fecha de Creación: <strong>{{$filters.formatDate(selectProductCreate_atModal)}}</strong></p>
            <p class="description"><strong id="label_modifications_configure_commission" >Modificaciones</strong></p>

            <table class="border-2 rounded-lg shadow-md table-sensitive" >
                    <thead>
                        <tr class="border-b-2">
                            <th class="px-4 py-2">
                                <label>Fecha de Cambio:</label>
                            </th>
                            <th>
                                <label>Comisiones en:</label>
                            </th>
                            <th>
                                <label>Cambiado por:</label>
                            </th>
                        </tr>
                    </thead>
            </table>
            <div style="height: 300px;overflow-y:scroll">
                <table class="border-2 rounded-lg shadow-md table-sensitive" >
                    <tbody style="overflow-y: scroll;"  >
                        <tr class="border-b-2 " v-for="(history,index)  in showHistoryModalUser" :key="index">
                            <td>
                                <label :id="'label_change_date_configure_commission'+index"  class="font-normal" style="color:#5A5A5A">{{$filters.formatDate( history.created_at)}} </label>
                            </td>
                            <td>
                                <label :id="'label_commission_in_configure_commission'+index" class="font-normal" style="color:#5A5A5A">{{history.enrollment_step_name}}</label>
                            </td>
                            <td>
                                <label :id="'label_changed_by_configure_commission'" class="font-normal" style="color:#5A5A5A">{{history.associate_info.name}} {{history.associate_info.paternal_name}}</label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </template>
          <template v-slot:footer>
            <div style="display:flex;justify-content:center;">
               <div class="w-full"  >
                    <button class="md:float-right	text-white border-2 border-orange-300 rounded-lg px-5 py-2 w-full md:w-1/4 font-extrabold text-xl" id="button_exit_configure_commission" @click="closeHistoryModal()" >
                        Salir
                    </button>
                </div>
            </div>
          </template>
        </Modal>

    </div>
</template>

<script>
import('@/assets/css/referredperson.css');
import('@/assets/css/stylesheet.css');
import('@/assets/css/referredperson.css');
import { onBeforeMount, reactive, ref } from 'vue-demi';
import Modal from '@/components/Modal/Modal.vue';
import {useVuelidate} from "@vuelidate/core"
import { helpers, minLength, requiredIf } from '@vuelidate/validators';
import validations from '@/helpers/validations';
import Pagination from "@/components/Forms/Pagination/Pagination.vue"
import ProductCommissionPayments from "@/classes/ProductCommissionPayments.js"
import Swal from 'sweetalert2';
import Loading from '@/components/Loading/VueLoading.vue'

export default {
    name:"SetUpProductCommissionPayments",
    components:{
        Modal,
        Pagination,
        Loading
    },
    setup(){
        let isLoading= ref(false);
        let responseProducts=ref([]);
        let showHistoryModal=ref(false);
        let showHistoryModalUser=ref();
        let validationsStructure=ref();
        let rulesLoaded=ref(false);
        let classProductCommissionPayments=new ProductCommissionPayments();
        let selectProductNameModal=ref();
        let selectProductCreate_atModal=ref();
        let _nameUserCreateModal=ref();

        let _inputSearch=ref({
            product_name:""
        });

        let totalRowQuerry=ref(); 
        let hidePagination=ref(true);
        const PaginationRef=ref(null);
        let _commissionsResponse=ref();

        let _validationInputSearch=reactive({
            product_name:{
                required:helpers.withMessage("El campo Nombre del producto es requerido.",requiredIf(function() {return validationsStructure.value.validations.search.required;})),
                minlength:helpers.withMessage(()=>{return "El campo Nombre del Producto debe tener mínimo  "+ validationsStructure.value.validations.search.minLength+" caracter."},(value)=>{return validationsStructure.value.validations.search.minLength<=value.length}),
                maxlength:helpers.withMessage(()=>{return "El campo Nombre del Producto debe tener máximo  "+ validationsStructure.value.validations.search.maxLength+" caracteres."},(value)=>{return validationsStructure.value.validations.search.maxLength>=value.length}),
                isAlphaNumeric:helpers.withMessage("El campo Nombre del Producto no admite caracteres especiales.",(value)=>value != ""? new RegExp(/^[a-zA-ZÀ-ÿ-0-9 ]+$/g).test(value): true)
            }
        });

        let v$=useVuelidate(_validationInputSearch,_inputSearch);

        onBeforeMount(async ()=>{
            const response=await validations.getStructureValidations("/json/commissions/productCommissionPayments.json");
            validationsStructure.value = response.data;
            rulesLoaded.value=true;
            getProductCommissionPayment();
        });


        async function openHistoryModal(modelValue){
            selectProductNameModal.value=modelValue.product_name;
            selectProductCreate_atModal.value=modelValue.created_at;
            _nameUserCreateModal.value= `${modelValue.associate_info.name} ${modelValue.associate_info.paternal_name}`;
            const getContent={
                token_auth:sessionStorage.getItem("login"),
                product_name:modelValue.product_name
            }
            const _response= await classProductCommissionPayments.get(getContent,"foncabsa/enrollment_commission_config/history"); 
                if(_response.data.response.has_errors){
                    let messages="";
                    response.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>"
                    });
                    Swal.fire({
                        title: "Aviso",
                        html:response.data.response.message+"<br>"+messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    }); 
                    setIdAttributeToAccepAndCancelButtonSwalFire('button_confirmation_assign_bonus', '');
                }else{
                    showHistoryModalUser.value=_response.data.response.enrollment_commission_config_list;
                    showHistoryModalUser.value.reverse()
                    showHistoryModal.value=true;
                }
        }

        function closeHistoryModal(){
            showHistoryModal.value=false;
        }

       async function getAssociateFiltred(value){
            const _response= await classProductCommissionPayments.get(value,"foncabsa/enrollment_commission_config/get_enrollment_commission_config_with_pagination");
                if(_response.data.response.has_errors){
                    let messages="";
                    response.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>"
                    });
                    Swal.fire({
                        title: "Aviso",
                        html:response.data.response.message+"<br>"+messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    }); 
                    setIdAttributeToAccepAndCancelButtonSwalFire('button_confirmation_assign_bonus', '');
                }else{
                    hidePagination.value=true;
                    responseProducts.value=_response.data.response.enrollment_commission_config.rows;
                    totalRowQuerry.value=await _response.data.response.enrollment_commission_config.total_rows>=10?_response.data.response.enrollment_commission_config.total_rows:10;
                    PaginationRef.value.setPagination(totalRowQuerry.value);
                }
        }

        async function getProductCommissionPayment(){
            isLoading.value=true
            const _response= await classProductCommissionPayments.get({token_auth:sessionStorage.getItem("login")},"foncabsa/enrollment_step/get_active");
             if(_response.data.response.has_errors){
                    let messages="";
                    response.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>"
                    });
                    Swal.fire({
                        title: "Aviso",
                        html:response.data.response.message+"<br>"+messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    }); 
                    setIdAttributeToAccepAndCancelButtonSwalFire('button_confirmation_assign_bonus', '');
                }else{
                    _commissionsResponse.value=_response.data.response.enrollment_step;
                }
            isLoading.value=false

        }

      async function getSaleCommissionConfigHistory(){
          this.v$.$validate();
            if(!this.v$.$error){
            isLoading.value=true
            const _response= await classProductCommissionPayments.get({token_auth:sessionStorage.getItem("login").token,product_name:_inputSearch.value.product_name},"foncabsa/enrollment_commission_config/get_by_product");
              if(_response.data.response.has_errors){
                    let messages="";
                    response.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>"
                    });
                    Swal.fire({
                        title: "Aviso",
                        html:response.data.response.message+"<br>"+messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    }); 
                }else{
                    if(_response.data.response.enrollment_commission_config_list>0 && _response.data.response.has_errors==false){
                        Swal.fire({
                            title: "Aviso",
                            html:_response.data.response.message,
                            icon: "info",
                            confirmButtonText: "OK",
                            confirmButtonColor: '#FEB72B'
                        });
                        responseProducts.value=[];
                        hidePagination.value=false;
                        return;
                    }
                    responseProducts.value=[];
                    if(_response.data.response.enrollment_commission_config!=null){
                        responseProducts.value=_response.data.response.enrollment_commission_config;
                        hidePagination.value=true;
                    }

                }
            isLoading.value=false

          }
        }

        function searchAfterDelete(){
            isLoading.value=true
            if(_inputSearch.value.product_name.length==0){
                getAssociateFiltred({
                    token_auth:sessionStorage.getItem("login"),
                    initial_row: 0,
                    final_row: 10
                });
            }
            isLoading.value=false
        }

        function updateSaleCommissionConfig(value,product){
            Swal.fire({
                icon:'warning',
                title:"Aviso",
                html:`¿Desea aplicar la comision en <strong>(${value})</strong> ? en producto <strong>(${product.product_name})</strong>.`,
                confirmButtonColor:"#FEB72B",
                confirmButtonText:"Aceptar",
                showCancelButton:true,
                cancelButtonText:"Cancelar",
                cancelButtonColor: '#FFFFFF'
            }).then(async res=>{
                if(res.value){
                    await updateDateProduct(product,value)
                }else{
                    _commissionsResponse.value=[];
                    getProductCommissionPayment();
                }
            })

            setIdAttributeToAccepAndCancelButtonSwalFire('button_confirmation_delete_assign_bonus', 'button_confirmation_cancel_delete_assign_bonus');
        }

        function  setIdAttributeToAccepAndCancelButtonSwalFire(idButtonConfirm, idButtonCancel) {
            $('.swal2-confirm').attr('id', idButtonConfirm);
            $('.swal2-cancel').attr('id', idButtonCancel);
        }

        async function updateDateProduct(value,status){
            isLoading.value=true
            const modelBody={
                token_auth: sessionStorage.getItem("login"),
                enrollment_step_name:status,
                enrollment_commission_config_id:value.id
            }
            const _response=await classProductCommissionPayments.save(modelBody,`/${process.env.VUE_APP_BUSINESS_FONCABSA}/enrollment_commission_config/update`);
            isLoading.value = false;
            if(_response.status==500){
                    Swal.fire({
                        title: "Error",
                        html:`<strong>${_response.data.response.message}.</strong>`,
                        icon: "error",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    return; 
                }
                if(_response.data.response.has_errors){
                    let messages="";
                    _response.data.errors.forEach(element => {
                        messages+="<strong>■ "+element+".</strong><br>"
                    });
                    Swal.fire({
                        title: "Aviso",
                        html:_response.data.response.message+"<br>"+messages,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    }); 
                }else{
                    isLoading.value=false
                    Swal.fire({
                        title: "Actualización exitosa.",
                        //text: _response.data.response.message,
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                    PaginationRef.value.reloadTableQuery();
                }
                setIdAttributeToAccepAndCancelButtonSwalFire('button_confirmation_assign_bonus', '');
        }
        

        return {
            responseProducts,
            showHistoryModal,
            openHistoryModal,
            closeHistoryModal,
            showHistoryModalUser,
            v$,
            rulesLoaded,
            validationsStructure,
            totalRowQuerry,
            getAssociateFiltred,
            hidePagination,
            PaginationRef,
            getSaleCommissionConfigHistory,
            _commissionsResponse,
            searchAfterDelete,
            updateSaleCommissionConfig,
            selectProductNameModal,
            selectProductCreate_atModal,
            _nameUserCreateModal,
            isLoading
        }
    }
}
</script>
<style >
.swal2-actions{
    direction: rtl;
}

#button_confirmation_cancel_delete_assign_bonus,#button_confirmation_cancel_add_assign_bonus{
    color: #810042;
    border: 1px solid #810042;
    transition: all 300ms;
}

#button_confirmation_cancel_delete_assign_bonus:hover,
#button_confirmation_cancel_add_assign_bonus:hover{
    color:white;
    border: 1px solid #810042;
    background-color: #810042 !important;
}

</style>
<style scoped>

.button_history_configure_commission:hover{
    transition: all 300ms;
}

.button_history_configure_commission:hover{
    background-color: #810042;
    color: white;
}

#button_exit_configure_commission{
    background-color:#FEB72B;
    transition: all 300ms;
}

#button_exit_configure_commission:hover{
    color:#FEB72B;
    background-color:white ;
}

.swal2-actions{
    direction: rtl;
}

#button_confirmation_cancel_delete_assign_bonus,#button_confirmation_cancel_add_assign_bonus{
    color: #810042;
    border: 1px solid #810042;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: center;
  padding: 10px  ;
}

tr:nth-child(even){background-color: #ffffff;}

th {
  background-color: #ffffff;
}

table.width200,table.rwd_auto {border:1px solid #810042;border-radius: 10px;width:100%;margin:0 0 50px 0}
    .width200 th,.rwd_auto th {background:#ffffff;text-align:center;padding-top: 20px;padding-bottom: 20px;}
    .width200 tr:last-child td, .rwd_auto tr:last-child td{border:50px}

.frame::-webkit-scrollbar {
  -webkit-appearance: none;
}

.width200 tbody tr { height: 70px;}


@media only screen and (max-width: 1024px)
{
    table.width200, .width200 thead, .width200 tbody, .width200 th, .width200 td, .width200 tr { display: block; }

    .width200 thead tr { position: absolute;top: -9999px;left: -9999px; }

    .width200 tr th:nth-child(5){display: none;}

    .width200 tbody tr { height: auto;}

    .width200 td { border: none;border-bottom: 1px solid #810042; position: relative;padding-left: 40% ;text-align:left }

    .width200 td:before {  position: absolute;top: 0px; left: 6px; width: 45%; padding-right: 10px; }

    .width200 td:nth-of-type(1):before { content: "Producto"; }
    .width200 td:nth-of-type(2):before { content: "Comisiones en:"; }
    .width200 td:nth-of-type(3):before { content: "Última Actualización"; }
    .width200 td:nth-of-type(4):before { content: "Creado por:"; }
    .width200 td:nth-of-type(5):before { content: "Fecha de registro"; }

    .descarto {display:none;}
    .fontsize {font-size:10px}

    .width200{
        border-radius: 0px;
    }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width : 320px) and (max-width : 480px)
{
    .descarto {display:none;}
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px)
{
    .descarto {display:none;}
    .fontsize {font-size:10px}
    
}

@media only screen and (min-width: 1024px)
{
    .color-cherry-border {border:1px solid #810042}
}


@media (max-width: 300px) {
  .pagination-items {
    height: 20px;
    width: 30px;
  }
}



@media only screen and (max-width: 1024px)
{
    table.table-sensitive, .table-sensitive thead, .table-sensitive tbody, .table-sensitive th, .table-sensitive td, .table-sensitive tr { display: block; }

    .table-sensitive thead tr { position: absolute;top: -9999px;left: -9999px; }

    .table-sensitive tr th:nth-child(5){display: none;}

    .table-sensitive tbody tr { height: auto;}

    .table-sensitive td { border: none;border-bottom: 1px solid #c3c3c3; position: relative;padding-left: 40%;text-align:center; height: 7vh; }

    .table-sensitive td:before {  position: absolute;top: 0px; left: 6px; width: 45%; padding-right: 10px; }

    td:nth-of-type(1):before { content: "Fecha de cambio:"; }
    td:nth-of-type(2):before { content: "Comisiones en:"; }
    td:nth-of-type(3):before { content: "Cambiado por:"; }
}


</style>