<template>
  <div>
    <div class="flex" v-if="totalRowQuerry!=undefined">
      <div class="m-auto">
        <ul class="flex border-2 rounded-lg">
          <li class="p-4 hover:bg-gray-300" style="color:#337ab7" @click="changePaginationByLessTen((_pagination.initial_row-10))" v-on:click="paginationSelectAfter()">
            <a class="w-5" >
              <span>&laquo;</span>
            </a>
          </li>
          <li class="w-10 p-4 hover:bg-gray-300"  style="color:#337ab7"      
          v-bind:class="{
            'active': numberPage.isActive,
          }" 
          v-for="(numberPage,index) in  _pagitanioItems" :key="index" @click="changePagination(numberPage.pageNumber)"
          v-on:click="paginationSelect(numberPage)">
            <a class="page-link">{{numberPage.pageNumber}}</a></li>
          <li class="p-4 hover:bg-gray-300" style="color:#337ab7" @click="changePaginationByMoreTen((_pagination.initial_row+10))" v-on:click="paginationSelectNext()">
            <a class="w-5">
              <span>&raquo;</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, onBeforeUpdate, ref } from 'vue-demi';
import Swal from 'sweetalert2';
export default {
  name: "Pagination",
  props: {
    totalRowQuerry: {},//Numero de elementos que hay en la consulta
    maxQueryItems: {},
  },
  setup(props,{emit}){
      let _pagitanioItems=ref([]);
      let _maxQueryItems=ref();
      let _pagination=ref(
          {
            token_auth:"",
            initial_row: 0,
            final_row: 10
          }
      );

      let _prop_totalRowQuerry=ref("");
      let _prop__maxQueryItems=ref();

      onBeforeMount(async ()=>{
          _pagination.value.token_auth=sessionStorage.getItem("login");
          emit("change-pagination",_pagination.value);
          _prop_totalRowQuerry.value=props.totalRowQuerry;
          _prop__maxQueryItems.value=props.maxQueryItems;        
        });

        async function setPagination(pagination){ //Este método inicializa el paginado y asigna un valor a la variable donde tendría que decir la paginación del prop, viene del padre
            if( _prop_totalRowQuerry.value==undefined || _prop_totalRowQuerry.value==null )
            {
                _prop_totalRowQuerry.value=pagination;
                const _macNumber=await Math.ceil(pagination/_prop__maxQueryItems.value );
                for(let index=1;index<=_macNumber;index++){
                  _pagitanioItems.value.push({pageNumber:index,isActive:index==1?true:false})
                }
                _maxQueryItems.value=props.maxQueryItems;
            }
        }

      function changePagination(pageNumber){
        pageNumber--;
        if(pageNumber>=0){
             _pagination.value.initial_row=((pageNumber+1)*_maxQueryItems.value)-_maxQueryItems.value;
             _pagination.value.final_row=(pageNumber+1)*_maxQueryItems.value;
             emit("change-pagination",_pagination.value);
        }
      }

      function changePaginationByMoreTen(value){
           if(value>=0 &&  _pagination.value.final_row<(_pagitanioItems.value.length *_maxQueryItems.value) ){
             _pagination.value.initial_row=_pagination.value.initial_row+value
             _pagination.value.final_row=_pagination.value.initial_row+_maxQueryItems.value;
           emit("change-pagination",_pagination.value);
        }else{
            Swal.fire({
                title: "Aviso",
                text:"No hay más elementos por mostrar.",
                icon: "info",
                confirmButtonText: "OK",
                confirmButtonColor: '#FEB72B'
            })
        }
      }

      function changePaginationByLessTen(value){
           if(value>=0){
                _pagination.value.initial_row=value
                _pagination.value.final_row=_pagination.value.initial_row+_maxQueryItems.value;
                emit("change-pagination",_pagination.value);
            }
            else{
                Swal.fire({
                    title: "Aviso",
                    text:"No es posible retroceder más, ya estas en la primera posición.",
                    icon: "info",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#FEB72B'
                })
            }
      }

      function reloadTableQuery(){
        _prop_totalRowQuerry.value=undefined;
         _pagitanioItems.value=[];
          emit("change-pagination",{
            token_auth:sessionStorage.getItem("login"),
            initial_row: 0,
            final_row: 10
          });
      }

      function paginationSelect(isActivePage){
        _pagitanioItems.value.forEach(element => {
          element.isActive=element.pageNumber==isActivePage.pageNumber?true:false;
        });
      }

      function paginationSelectNext(){
        for (let index = 0; index < _pagitanioItems.value.length; index++) {
          if(_pagitanioItems.value[index].isActive==true && (index+1)<_pagitanioItems.value.length){
            _pagitanioItems.value[index].isActive=false;
            _pagitanioItems.value[(index+1)].isActive=true;
            break;
          }
        }
      }

       function paginationSelectAfter(){
        for (let index = 0; index < _pagitanioItems.value.length; index++) {
          if(_pagitanioItems.value[index].isActive==true && index!=0){
            _pagitanioItems.value[index].isActive=false;
            _pagitanioItems.value[(index-1)].isActive=true;
            break;
          }

        }
      }

      return{
          _pagitanioItems,
          _pagination,
          _prop_totalRowQuerry,
          _prop__maxQueryItems,
          changePaginationByMoreTen,
          changePaginationByLessTen,
          changePagination,
          reloadTableQuery,
          paginationSelect,
          setPagination,
          paginationSelectNext,
          paginationSelectAfter
      }
  }
};
</script>

<style>
.active{
  background: #337ab7 !important;
  color: white !important;
}
</style>