const host = process.env.VUE_APP_CCAPI
const business = process.env.VUE_APP_BUSINESS_FONCABSA
import api from '@/api/api'

export default class ProductCommissionPayments {
    save = async (commissionScheme, url) => {
        const API_URL = `${host}${url}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const params = commissionScheme;
        console.log(API_URL)

        return await api.httpPost(API_URL, config, params).catch(err => { return err.response });
    }

    get = async (commissionScheme, url) => {
        const apiUrl = `${host}/${url}`;
        const config = {
            headers: {
                "Content-Type": "application/json",
            }
        };
        const params = commissionScheme;
        try {
            return await api.httpGet(apiUrl, config, commissionScheme);
        } catch (error) {
            throw error.response;
        }
    }
}